import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import overlay from './images/overlay_large.png'; // Default import

const NoPage = () => {
    const navigate = useNavigate(); // Initialize navigate function


  return (
    <div className='password-container'>
      <img
        src={overlay} // Use the overlay image
        alt="Overlay"
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100vw', // Ensure the overlay spans the full width of the viewport
          height: '100vh', // Ensure the overlay spans the full height of the viewport
          mixBlendMode: 'darken', // Apply darken blending mode
          pointerEvents: 'none', // Prevent interaction interference
          zIndex: 9999, // Ensure the overlay is on top
          objectFit: 'cover', // Ensure the image scales correctly without distortion
          overflow: 'hidden' // Crop the overlay to fit within the screen size
        }}
      />
      <button className='back-button' onClick={() => navigate('/')}>
                ←
            </button>
      <p>password: silhouette</p>
    </div>
  );
};

export default NoPage;
