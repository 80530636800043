import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import './App.css'; // Ensure to import your CSS file
import overlay from './images/overlay_large.png'; // Default import

const PasswordPage = () => {
    const [password, setPassword] = useState('');
    const [error, setError] = useState(false); // Add state for error message
    const inputRef = useRef(null);
    const navigate = useNavigate(); // Initialize navigate function

    // Store the scroll position
    const scrollPosition = useRef(0);

    const handleInputChange = (e) => {
        // Convert the input value to lowercase and limit to 10 characters
        const newPassword = e.target.value.slice(0, 10).toLowerCase();
        setPassword(newPassword);

        // Check if the entered password is exactly 10 characters
        if (newPassword.length === 10) {
            if (newPassword === 'silhouette') {
                navigate('/silhouette'); // Redirect to the desired page if correct
            } else {
                setError(true); // Show error if password is incorrect
            }
        } else {
            setError(false); // Hide error if less than 10 characters are typed
        }
    };

    // Automatically focus the input on load and keep it focused
    useEffect(() => {
        const focusInput = () => {
            if (inputRef.current) {
                inputRef.current.focus();
            }
        };

        // Focus on component mount
        focusInput();

        // Add an event listener to refocus input when it loses focus
        window.addEventListener('click', focusInput);

        return () => {
            // Clean up the event listener when the component unmounts
            window.removeEventListener('click', focusInput);
        };
    }, []);

    // Handle scroll position when keyboard is shown or hidden
    useEffect(() => {
        const handleFocus = () => {
            // Store the current scroll position when input gains focus
            scrollPosition.current = window.scrollY;
        };

        const handleBlur = () => {
            // Scroll back to the stored position when the input loses focus (keyboard closes)
            window.scrollTo({ top: scrollPosition.current, behavior: 'smooth' });
        };

        if (inputRef.current) {
            inputRef.current.addEventListener('focus', handleFocus);
            inputRef.current.addEventListener('blur', handleBlur);
        }

        return () => {
            if (inputRef.current) {
                inputRef.current.removeEventListener('focus', handleFocus);
                inputRef.current.removeEventListener('blur', handleBlur);
            }
        };
    }, []);

    return (
        <div>
            <img
                src={overlay} // Use the overlay image
                alt="Overlay"
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100vw', // Ensure the overlay spans the full width of the viewport
                    height: '100vh', // Ensure the overlay spans the full height of the viewport
                    pointerEvents: 'none', // Prevent interaction interference
                    zIndex: 9999, // Ensure the overlay is on top
                    objectFit: 'cover', // Ensure the image scales correctly without distortion
                    overflow: 'hidden', // Crop the overlay to fit within the screen size
                    mixBlendMode: 'darken' // Apply darken blending mode

                }}
            />
            <button className='back-button' onClick={() => navigate('/')}>
                ←
            </button>
            <div className="password-container">
                <h1 className="password-title">enter your password</h1>
                {/* Single text box for password input */}
                <input
                    type="text"
                    ref={inputRef}
                    value={password}
                    onChange={handleInputChange}
                    maxLength={10}
                    className="password-input"
                    style={{
                        width: `${Math.max(password.length, 1) + 1}ch`, // Use ch unit to account for character width
                    }}
                />
                {/* Conditionally render the error message */}
                {error && <p className="error-message">wrong password</p>}
            </div>
        </div>
    );
};

export default PasswordPage;
