import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import EscapeQuestion from './EscapeQuestion';
import PasswordPage from './PasswordPage';
import SuccessPage from './SuccessPage'; // Import your success page component
import AnimationWithMusic from './Animation';
import NoPage from './No.js'

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<EscapeQuestion />} />
        <Route path="/password" element={<PasswordPage />} />
        <Route path="/silhouette" element={<SuccessPage />} /> {/* Define your success page route */}
        <Route path='/animation' element ={<AnimationWithMusic />} />
        <Route path='/no' element ={<NoPage />} />

      </Routes>
    </Router>
  );
};

export default App;
