import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import overlay from './images/overlay_large.png'; // Default import

const SuccessPage = () => {
    const [loading, setLoading] = useState(true); // Track loading state
    const navigate = useNavigate(); // Initialize navigate function

    useEffect(() => {
        // Simulate loading time (e.g., 10 seconds)
        const loadingTimer = setTimeout(() => {
            setLoading(false); // Set loading to false after time
            navigate('/animation'); // Navigate to the Animation route
        }, 10000); // Set to 10 seconds (10000 ms)

        // Cleanup timer on unmount
        return () => {
            clearTimeout(loadingTimer);
        };
    }, [navigate]);

    return (
        <div>
            `<img
                src={overlay} // Use the overlay image
                alt="Overlay"
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100vw', // Ensure the overlay spans the full width of the viewport
                    height: '100vh', // Ensure the overlay spans the full height of the viewport
                    mixBlendMode: 'darken', // Apply darken blending mode
                    pointerEvents: 'none', // Prevent interaction interference
                    zIndex: 9999, // Ensure the overlay is on top
                    objectFit: 'cover', // Ensure the image scales correctly without distortion
                    overflow: 'hidden' // Crop the overlay to fit within the screen size
                }}
            />`
            {loading && ( // Show loading UI only while loading
                <div className='bar'>
                    <h1
                        className='loading-title'
                        style={{
                            textAlign: 'left'
                        }}
                    >
                        recharging...
                    </h1>
                    <div className='bar-box'>
                        <div className='border-fill'></div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default SuccessPage;
