import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook
import overlay from './images/overlay_large.png'; // Default import

const EscapeQuestion = () => {
  const navigate = useNavigate(); // Initialize the navigate function

  const handleOptionClick = (option) => {
    if (option === 'Yes') {
      navigate('/password'); // Navigate to password page route when Yes is clicked
    } else if (option === 'No') {
      window.location.href = '/no'; // Redirect to the specified URL
    }
  };

  return (
    <div className='password-container'>
      <img
        src={overlay} // Use the overlay image
        alt="Overlay"
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100vw', // Ensure the overlay spans the full width of the viewport
          height: '100vh', // Ensure the overlay spans the full height of the viewport
          mixBlendMode: 'darken', // Apply darken blending mode
          pointerEvents: 'none', // Prevent interaction interference
          zIndex: 9999, // Ensure the overlay is on top
          objectFit: 'cover', // Ensure the image scales correctly without distortion
          overflow: 'hidden' // Crop the overlay to fit within the screen size
        }}
      />
      <h1>would you like an escape?</h1>
      <div className='buttons'>
        <button onClick={() => handleOptionClick('Yes')}>
          yes
        </button>
        <button onClick={() => handleOptionClick('No')}>
          no
        </button>
      </div>
    </div>
  );
};

export default EscapeQuestion;
