import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import hoverSound from './music/hover.wav'; // Import hover sound
import oob from './images/oob.svg';
import teaserVideo from './videos/teaser_video.mp4'; // Import your large video file
import teaserVideoSmall from './videos/teaser_video_small.mp4'; // Import your small video file
import overlay from './images/overlay_large.png'; // Default import

const AnimationWithImages = () => {
    const [isPlaying, setIsPlaying] = useState(false);
    const navigate = useNavigate();
    const videoRef = useRef(null); // Create a ref for the video element

    // Check if the device supports touch
    const isTouchDevice = window.matchMedia("(pointer: coarse)").matches;

    const handleStart = () => {
        setIsPlaying(true);
        if (videoRef.current) {
            videoRef.current.play().catch(err => console.error("Video playback failed:", err));
        }
    };

    const playHoverSound = () => {
        // Play hover sound only if not a touch device
        if (!isTouchDevice) {
            const hoverAudio = new Audio(hoverSound); // Create a new Audio object for hover sound
            hoverAudio.play().catch(err => console.error("Hover sound playback failed:", err));
        }
    };

    // Determine the video source based on screen size
    const videoSource = window.innerWidth < 768 ? teaserVideoSmall : teaserVideo;

    useEffect(() => {
        const video = videoRef.current;

        // Navigate to home when the video ends
        const handleVideoEnd = () => {
            navigate('/');
        };

        if (video) {
            video.addEventListener('ended', handleVideoEnd);
        }

        // Cleanup function to remove event listener
        return () => {
            if (video) {
                video.removeEventListener('ended', handleVideoEnd);
            }
        };
    }, [navigate]);

    return (
        <div className="animation-container" style={{ position: 'relative' }}>
            <img
                src={overlay} // Use the overlay image
                alt="Overlay"
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100vw', // Ensure the overlay spans the full width of the viewport
                    height: '100vh', // Ensure the overlay spans the full height of the viewport
                    mixBlendMode: 'darken', // Apply darken blending mode
                    pointerEvents: 'none', // Prevent interaction interference
                    zIndex: 9999, // Ensure the overlay is on top
                    objectFit: 'cover', // Ensure the image scales correctly without distortion
                    overflow: 'hidden' // Crop the overlay to fit within the screen size
                }}
            />
            {!isPlaying ? (
                <div className="svg-button" onClick={handleStart} onMouseEnter={playHoverSound} // Play sound on hover
                    style={{ position: 'relative', display: 'inline-block', cursor: 'pointer' }}>
                    <img
                        src={oob}
                        alt="Start Animation"
                        className="svg-guy"
                    />
                    <p className='play'
                        >play</p>
                </div>
            ) : (
                <video
                    ref={videoRef}
                    src={videoSource}
                    className="animated-video"
                    autoPlay
                    playsInline
                    onEnded={() => navigate('/')}
                    style={{ width: '100%', height: '100%' }} // Adjusted for full height
                />
            )}
        </div>
    );
};

export default AnimationWithImages;
